import { createSelector } from 'reselect'
import { get, map, memoize, filter, sortBy } from 'lodash'
import { NAMESPACE } from './constants'
import { ISitesModel } from './interfaces'

// const getState = (state: any) => state;

const getSitesState = (state: any): ISitesModel => state[NAMESPACE]

export const getCurrentSearchTerm = createSelector(
  getSitesState,
  sites => sites.currentSearchTerm,
)

export const getSitesById = createSelector(getSitesState, sites => sites.byId)

// export const getSitesBySearchTerm = createSelector([getSitesState, getSitesById], (sites, byId) => memoize((term) =>
//     get(sites, `bySearchTerms[${term}]`, []).map((id: string) => get(byId, [id]))
// ));

export const getSitesBySearchTerm = createSelector(getSitesById, byId =>
  memoize((term: { toLowerCase: () => void }) =>
    filter(byId, (site: any) =>
      site.name.toLowerCase().includes(term.toLowerCase()),
    ),
  ),
)

export const getSites = createSelector(getSitesById, byId => map(byId, s => s))

/** @todo move to `ui/sites` */
export const getSitesInAlphabeticalOrder = createSelector(getSites, sites =>
  sortBy(sites, (s: any) => s.name),
)

export const getSiteById = createSelector(getSitesById, byId =>
  memoize((id: number) => get(byId, [id])),
)

export const getSiteByName = createSelector(getSitesById, byId =>
  memoize((name: string) =>
    get(
      filter(byId, (s: any) => s.name === name),
      '[0]',
      null,
    ),
  ),
)

export const getSiteByPath = createSelector(getSitesById, byId =>
  memoize((path: string) =>
    get(
      filter(byId, (s: any) => s.path === `/${path}/`),
      '[0]',
      null,
    ),
  ),
)

export const getCurrentSite = createSelector(getSitesState, sites =>
  get(sites, 'currentSite'),
)

export const getCurrentSitePath = createSelector(getCurrentSite, currentSite =>
  get(currentSite, 'path', '').replace(/\//g, ''),
)

export const getCurrentSiteID = createSelector(getCurrentSite, currentSite =>
  get(currentSite, 'id', ''),
)

export const getCurrentSiteName = createSelector(getCurrentSite, currentSite =>
  get(currentSite, 'name', ''),
)

export const getCurrentSiteChannelCount = createSelector(
  getCurrentSite,
  currentSite => get(currentSite, 'channel_count', 0),
)

export const getCurrentSitePermission = createSelector(
  getCurrentSite,
  currentSite => (permissionKey: string) =>
    get(currentSite, ['permissions', permissionKey], true),
)

export const getCurrentSiteProtectionOnly = createSelector(
  getCurrentSite,
  currentSite => get(currentSite, 'protection_only', false),
)

export const getCurrentSiteNameCropped = createSelector(
  getCurrentSite,
  currentSite => {
    const siteName = get(currentSite, 'name', '')
    return siteName.length > 35 ? siteName.substring(0, 35) + '...' : siteName
  },
)

export const getIsCurrentSiteTmtPtp = createSelector(
  getCurrentSite,
  currentSite => get(currentSite, 'tmt_ptp', false),
)

export const getPreviousSite = createSelector(
  getSitesState,
  sites => sites.previousSite,
)

export const getPreviousSitePath = createSelector(
  getPreviousSite,
  previousSite => get(previousSite, 'path', '').replace(/\//g, ''),
)

export const getPreviousSiteName = createSelector(
  getPreviousSite,
  previousSite => get(previousSite, 'name', ''),
)

export const getSitesSchema = createSelector(
  getSitesState,
  sites => sites.schema,
)

export const getSitesSchemaMethods = createSelector(
  getSitesSchema,
  schema => schema?.methods || [],
)

export const getSitesSchemaProperties = createSelector(getSitesSchema, schema =>
  get(schema, 'schema.properties', {}),
)

export const getTmgPropertiesFromSchema = createSelector(
  getSitesState,
  schema => get(schema, 'schema.schema.properties._tmg.properties', {}),
)

export const getTmuPropertiesFromSchema = createSelector(
  getSitesSchema,
  schema => get(schema, 'schema.properties._tmu.properties', {}),
)

export const getBusinessUnitOptionsFromSchema = createSelector(
  getSitesSchema,
  schema => get(schema, 'schema.properties._business_unit.enum', []),
)

export const getGatewayOptionsFromSchema = createSelector(
  getSitesSchema,
  schema => get(schema, 'schema.properties._gateway.enum', []),
)
